import React from 'react';
import { Stack, Typography } from '@mui/material';

function CopyRight() {

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{ alignItems: 'center', mt: 2 }}
    >
      <Typography sx={{ textAlign: 'center' }}>
        Ontwikkeld met <strong>❤</strong> voor{' '}
        <strong>Marketing Automation</strong> door
        <strong> AppCenter Nederland</strong> - &copy;{' '}
        {new Date().getFullYear()} Alle rechten voorbehouden
      </Typography>
      {/*<TextField*/}
      {/*  select*/}
      {/*  size="small"*/}
      {/*  value={isDarkTheme ? 'dark' : 'light'}*/}
      {/*  onChange={(e) => toggleDarkTheme(e.target.value === 'dark')}*/}
      {/*  InputProps={{*/}
      {/*    startAdornment: (*/}
      {/*      <InputAdornment position={'start'}>*/}
      {/*        {isDarkTheme ? <DarkModeIcon /> : <LightModeIcon />}*/}
      {/*      </InputAdornment>*/}
      {/*    ),*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <MenuItem value="light">Licht</MenuItem>*/}
      {/*  <MenuItem value="dark">Donker</MenuItem>*/}
      {/*</TextField>*/}
    </Stack>
  );
}

export { CopyRight };

import NextHead from 'next/head';
import React, { useCallback } from 'react';
import { Theme } from '@mui/material/styles';

const ICON_PATH =
  'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/icons';

const SPLASHSCREEN_PATH =
  'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/splashscreens';

const appleSplashScreens = [
  '2048x2732',
  '1668x2224',
  '1536x2048',
  '1125x2436',
  '1242x2208',
  '750x1334',
  '640x1136',
];
const favicons = ['196x196', '128x128', '96x96', '16x16'];
const appleIcons = [
  '57x57',
  '60x60',
  '72x72',
  '76x76',
  '114x114',
  '120x120',
  '144x144',
  '152x152',
];

interface PageOpenGraphProps {
  app: 'communiqate' | 'marqting' | 'acn';
  theme: Theme;
}

export function PageOpenGraph(props: PageOpenGraphProps) {
  const { app, theme } = props;

  const getAppName = useCallback(() => {
    switch (app) {
      case 'marqting':
        return 'MarQting';
      case 'communiqate':
        return 'CommuniQate';
      default:
        return 'AppCenter Nederland';
    }
  }, [app]);

  const appName = getAppName();
  return (
    <NextHead>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />

      <link
        href={`${ICON_PATH}/${app}-favicon.ico`}
        rel={'shortcut icon'}
        type={'image/x-icon'}
        sizes={'any'}
      />

      {favicons.map((favicon, key) => (
        <link
          key={key + 1}
          href={`${ICON_PATH}/${app}-favicon-${favicon}.png`}
          rel={'icon'}
          type={'image/png'}
        />
      ))}

      {appleIcons.map((appleIcon, key1) => (
        <link
          key={key1 + 50}
          href={`${ICON_PATH}/${app}-apple-touch-icon-${appleIcon}.png`}
          rel={'apple-touch-icon'}
          type={'image/png'}
          sizes={appleIcon}
        />
      ))}

      {appleSplashScreens.map((splashscreen, key2) => (
        <link
          key={key2 + 100}
          href={`${SPLASHSCREEN_PATH}/acn-apple-splashscreen-${splashscreen}.jpg`}
          rel={'apple-touch-startup-image'}
          sizes={splashscreen}
          type={'image/jpg'}
        />
      ))}

      <meta
        name={'theme-color'}
        content={
          theme.palette.primary.main === '#fff'
            ? theme.palette.secondary.main
            : theme.palette.primary.main
        }
      />
      <meta name={'application-name'} content={appName} />
      <meta name={'apple-mobile-web-app-title'} content={appName} />
      <meta name={'msapplication-TileColor'} content={'#fff'} />
      <meta
        name={'apple-mobile-web-app-status-bar-style'}
        content={'default'}
      />
      <meta name={'apple-mobile-web-app-capable'} content={'yes'} />
      <meta
        name={'msapplication-TileImage'}
        content={`${ICON_PATH}/${app}-mstile-144x144.png`}
      />
      <meta
        name={`msapplication-square310x310logo`}
        content={`${ICON_PATH}/${app}-mstile-310x310.png`}
      />
      <meta
        name={`msapplication-square150x150logo`}
        content={`${ICON_PATH}/${app}-mstile-150x150.png`}
      />
      <meta
        name={`msapplication-square70x70logo`}
        content={`${ICON_PATH}/${app}-mstile-70x70.png`}
      />
      <meta
        name={'msapplication-wide310x150logo'}
        content={`${ICON_PATH}/${app}-mstile-310x150.png`}
      />
    </NextHead>
  );
}

import {useEffect, useRef, useState} from 'react';

/**
 * Check if an element is intersecting with the current element
 * @param element
 * @param rootMargin
 */
export const useIntersection = (element: any, rootMargin?: string) => {
  const [intersecting, setIsIntersecting] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver>();

  async function initializeObserver() {
    await import('intersection-observer');
  }

  useEffect(() => {
    let el;
    (async () => {
      await initializeObserver();
      const newObserver = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { rootMargin }
      );

      const currentElement = element.current;

      el = currentElement;
      currentElement && newObserver.observe(currentElement);
      observer.current = newObserver;
    })();

    return () => observer.current && observer.current?.unobserve(el);
  }, [element, rootMargin]);

  return intersecting;
};

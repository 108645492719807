import { createTheme } from '@mui/material/styles';
import { DefaultDarkTheme } from './default-dark.theme';

export const MarqtingDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#464d69',
      paper: '#464d69',
    },
    primary: {
      light: '#C5B3F0',
      main: '#AC92EA',
      dark: '#9B83D3',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#7e8296',
      main: '#464d69',
      dark: '#3f455f',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '3.75rem',
        },
        body1: {
          fontSize: '1.2rem',
        },
        h3: {
          fontWeight: 500,
        },
        h4: {
          fontWeight: 500,
          fontSize: '2.5rem',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
  },
});

//Copied from: https://mui.com/material-ui/customization/typography/#responsive-font-sizes
MarqtingDarkTheme.components.MuiTypography.styleOverrides.h1 = {
  fontSize: '3.75rem',
  [DefaultDarkTheme.breakpoints.down('md')]: {
    fontSize: '2.75rem',
  },
};

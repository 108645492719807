import { createTheme } from '@mui/material/styles';
import { DefaultDarkTheme } from './default-dark.theme';

export const CommuniqateDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#121212',
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    primary: {
      light: '#59afa5',
      main: '#128c7e',
      dark: '#107e71',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#595959',
      main: '#121212',
      dark: '#101010',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '3.75rem',
        },
        body1: {
          fontSize: '1.2rem',
        },
        h3: {
          fontWeight: 500,
        },
        h4: {
          fontWeight: 500,
          fontSize: '2.5rem',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
  },
});

//Copied from: https://mui.com/material-ui/customization/typography/#responsive-font-sizes
CommuniqateDarkTheme.components.MuiTypography.styleOverrides.h1 = {
  fontSize: '3.75rem',
  [DefaultDarkTheme.breakpoints.down('md')]: {
    fontSize: '2.75rem',
  },
};

export const HOME_ROUTE = '/';
export const ACN_CLOUD_ROUTE = '/acn-cloud';

export const KENNISBANK_ROUTE = '/kennisbank';
export const KENNISBANK_CATEGORY_ITEM_ROUTE = '/kennisbank/categorieen/';
export const KENNISBANK_ARTICLE_ITEM_ROUTE = '/kennisbank/artikelen/';

export const KLANTCASES_ROUTE = '/klantcases';
export const CUSTOMER_SERVICE_ROUTE = '/informatie/klantenservice';
export const TERMS_OF_SERVICE_ROUTE = '/informatie/algemene-voorwaarden';
export const PRIVACY_POLICY_ROUTE = '/informatie/privacy-voorwaarden';
export const VERWERKERS_OVEREENKOMST_ROUTE =
  '/informatie/verwerkersovereenkomst';
export const COOKIE_POLICY_ROUTE = '/informatie/cookies';

export const COMMUNIQATE_ROUTE = 'https://www.communiqate.nl';
export const COMMUNIQATE_FUNCTIONALITIES_ROUTE = 'https://www.communiqate.nl';
export const COMMUNIQATE_CUSTOMER_CASES_ROUTE = 'https://www.communiqate.nl';
export const COMMUNIQATE_PRICING_ROUTE = 'https://www.communiqate.nl';

export const MARQTING_ROUTE = '/acn-cloud/marqting';
export const MARQTING_FUNCTIONALITIES_ROUTE =
  '/acn-cloud/marqting/functionaliteiten';
export const MARQTING_CUSTOMER_CASES_ROUTE = '/acn-cloud/marqting/klantcases';
export const MARQTING_PRICING_ROUTE = '/acn-cloud/marqting/prijzen';

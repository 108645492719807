import { SvgIcon, SvgIconProps } from '@mui/material';

export function CommuniqateIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 259.4739 180.6165"
      preserveAspectRatio={'xMidYMid meet'}
      {...props}
    >
      <g id="Layer_1-2" style={{ fill: 'currentcolor' }}>
        <g>
          <path d="M245.4354,137.3929c8.8964-13.7212,14.0385-29.8433,14.0385-47.0859C259.4739,40.512,216.6436,0,163.9961,0c-2.8049,0-5.5781,.1382-8.3272,.3654-9.0539,.7422-17.7495,2.7039-25.912,5.6793,9.2695,3.3842,17.834,8.0903,25.4517,13.8839,2.8873-.3033,5.8157-.4784,8.7876-.4784,5.5903,0,11.0376,.566,16.2936,1.6153,34.6076,6.9051,60.6187,35.3212,60.6187,69.2415s-26.0112,62.3378-60.6187,69.2441c-5.2559,1.048-10.7033,1.614-16.2936,1.614-2.9718,0-5.9003-.1751-8.7876-.4797-9.0562-.9542-17.6426-3.3574-25.471-6.9659-6.4864-2.9858-12.4546-6.7847-17.7421-11.2611-15.3029-12.9601-24.9105-31.54-24.9105-52.1514s9.6075-39.1898,24.9105-52.1499c-5.2584-1.3146-10.7943-2.0342-16.5177-2.0342-2.7841,0-5.5189,.1829-8.2025,.5039-11.7778,15.0182-18.7557,33.5916-18.7557,53.6802s6.978,38.6633,18.7557,53.6815c4.8854,6.2315,10.6029,11.8424,16.9863,16.6969,7.6213,5.7962,16.2028,10.4987,25.476,13.8787,8.1675,2.977,16.8652,4.9436,25.9313,5.6845,2.7491,.2284,5.5223,.368,8.3272,.368,22.1916,0,42.6222-7.2171,58.8528-19.2763l30.9305,8.6841-8.3441-32.6314Z" />
          <path d="M155.2086,19.9286c-7.6176-5.7936-16.1822-10.4997-25.4517-13.8839C119.1116,2.1545,107.5556,0,95.4778,0,42.8294,0,0,40.512,0,90.307c0,17.2426,5.1398,33.3647,14.0397,47.0859l-8.3441,32.6314,30.9306-8.6841c16.2283,12.0592,36.6599,19.2763,58.8516,19.2763,2.8084,0,5.589-.1395,8.3369-.368,9.0648-.7435,17.7591-2.7075,25.9229-5.6845-9.2732-3.38-17.8547-8.0825-25.476-13.8787-2.8835,.3046-5.8107,.4797-8.7838,.4797-5.6059,0-11.0704-.5686-16.3348-1.6267-34.5879-6.9191-60.5776-35.3249-60.5776-69.2314,0-39.0695,34.5009-70.8568,76.9123-70.8568,2.9731,0,5.9003,.1725,8.79,.4771,9.0549,.9542,17.6403,3.3626,25.4698,6.9674,25.2579,11.6304,42.6512,35.679,42.6512,63.4123,0,20.6037-9.5988,39.1734-24.8899,52.1335,5.3192,1.3362,10.8586,2.0519,16.4972,2.0519,2.737,0,5.4836-.1892,8.2159-.5228,11.7666-15.0143,18.7423-33.5791,18.7423-53.6625,0-28.425-13.9708-53.8109-35.7457-70.3784Z" />
          <path d="M163.9961,19.4501c5.5903,0,11.0376,.566,16.2936,1.6153-7.0968-8.0027-15.39-14.9839-24.6208-20.7001-9.0539,.7422-17.7495,2.7039-25.912,5.6793,9.2695,3.3842,17.834,8.0903,25.4517,13.8839,2.8873-.3033,5.8157-.4784,8.7876-.4784Z" />
          <path d="M95.4778,161.1651c-5.6059,0-11.0704-.5686-16.3348-1.6267,7.1707,8.0978,15.5051,15.0538,24.6716,20.7102,9.0648-.7435,17.7591-2.7075,25.9229-5.6845-9.2732-3.38-17.8547-8.0825-25.476-13.8787-2.8835,.3046-5.8107,.4797-8.7838,.4797Z" />
        </g>
      </g>
    </SvgIcon>
  );
}

import { Fab, Tooltip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';

export function WhatsappMessenger() {
  return (
    <Tooltip
      color={'primary'}
      title={'Stel je vraag via Whatsapp'}
      placement={'left'}
    >
      <Fab
        aria-label={'Whatsapp Messenger'}
        sx={{
          width: 70,
          height: 70,
          position: 'fixed',
          top: 'auto',
          right: 12,
          bottom: 24,
          margin: '0 12px',
          boxShadow: 'rgb(0 0 0 / 15%) 0px 4px 12px 0px',
        }}
        href={`https://wa.me/31850604011`}
        target={'_blank'}
        color={'primary'}
        rel="noopener nofollow"
      >
        <WhatsAppIcon sx={{ color: 'inherit', width: 40, height: 40 }} />
      </Fab>
    </Tooltip>
  );
}

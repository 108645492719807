import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

function getWindowDimensions() {
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    };
  }

  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const theme = useTheme();

  const dimensions = getWindowDimensions();
  const [windowDimensions, setWindowDimensions] = useState(dimensions);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      const dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
      setIsMobile(dimensions.width <= theme.breakpoints.values.lg);
    }

    setIsMobile(dimensions.width <= theme.breakpoints.values.lg);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dimensions, theme]);

  return {
    ...windowDimensions,
    isMobile,
  };
}

export { useWindowDimensions };

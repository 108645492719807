import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Fade,
  FadeProps,
  Grid,
  GridProps,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useIntersection, useWindowDimensions } from '../../hooks';

export interface SectionProps {
  children?: React.ReactNode;
  caption?: string | React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  backgroundImage?: string;
  backgroundPosition?: string;
  backgroundSize?: number | string;
  backgroundRepeat?: string;
  backgroundColor?: string;
  contentContainerProps?: ContainerProps;
  background?: string;
  actions?: React.ReactNode[];
  SectionBoxProps?: BoxProps;
  ContainerProps?: ContainerProps;
  captionTypographyProps?: TypographyProps;
  titleTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
  actionsStackProps?: StackProps;
  contentStackProps?: StackProps;
  SectionContentBoxProps?: BoxProps;
  position: 'right' | 'left' | 'center';
  onChangeInViewport?: (inViewPort: boolean) => void;
  SideComponent?: React.ReactNode;
  contentBeforeTitle?: React.ReactNode;
  disableFade?: boolean;
}

export function Section(props: SectionProps) {
  const {
    children,
    position,
    caption,
    title,
    backgroundImage,
    backgroundColor,
    backgroundSize,
    backgroundPosition,
    background,
    backgroundRepeat,
    contentBeforeTitle,
    subtitle,
    contentContainerProps,
    actions,
    onChangeInViewport,
    SectionBoxProps,
    subtitleTypographyProps,
    titleTypographyProps,
    captionTypographyProps,
    actionsStackProps,
    contentStackProps,
    ContainerProps,
    SectionContentBoxProps,
    SideComponent,
    disableFade,
  } = props;

  return (
    <Box
      component="section"
      {...SectionBoxProps}
      sx={{
        contentVisibility: 'auto',
        backgroundImage: backgroundImage
          ? `url(${backgroundImage})`
          : undefined,
        background: background,
        bgcolor: backgroundColor,
        backgroundRepeat: backgroundRepeat ? backgroundRepeat : 'no-repeat',
        backgroundSize: backgroundSize ? backgroundSize : 'contain',
        backgroundPosition: backgroundPosition ? backgroundPosition : 'right',
        ...SectionBoxProps?.sx,
      }}
    >
      {position === 'center' && (
        <CenterContent
          position={position}
          title={title}
          subtitle={subtitle}
          caption={caption}
          actions={actions}
          contentBeforeTitle={contentBeforeTitle}
          captionTypographyProps={captionTypographyProps}
          actionsStackProps={actionsStackProps}
          contentStackProps={contentStackProps}
          contentContainerProps={contentContainerProps}
          subtitleTypographyProps={subtitleTypographyProps}
          titleTypographyProps={titleTypographyProps}
          ContainerProps={ContainerProps}
          SectionContentBoxProps={SectionContentBoxProps}
          onChangeInViewport={onChangeInViewport}
          disableFade={disableFade}
        >
          {children}
        </CenterContent>
      )}
      {position === 'left' && (
        <SideContent
          position={position}
          title={title}
          contentBeforeTitle={contentBeforeTitle}
          subtitle={subtitle}
          caption={caption}
          actions={actions}
          contentContainerProps={contentContainerProps}
          captionTypographyProps={captionTypographyProps}
          actionsStackProps={actionsStackProps}
          GridItemContainerProps={{ alignSelf: 'center' }}
          contentStackProps={contentStackProps}
          subtitleTypographyProps={subtitleTypographyProps}
          titleTypographyProps={titleTypographyProps}
          SectionContentBoxProps={SectionContentBoxProps}
          onChangeInViewport={onChangeInViewport}
          SideComponent={SideComponent}
          disableFade={disableFade}
        >
          {children}
        </SideContent>
      )}
      {position === 'right' && (
        <SideContent
          position={position}
          title={title}
          subtitle={subtitle}
          contentContainerProps={contentContainerProps}
          caption={caption}
          GridItemContainerProps={{ alignSelf: 'center' }}
          actions={actions}
          captionTypographyProps={captionTypographyProps}
          actionsStackProps={actionsStackProps}
          contentStackProps={contentStackProps}
          subtitleTypographyProps={subtitleTypographyProps}
          titleTypographyProps={titleTypographyProps}
          GridContainerProps={{ flexDirection: 'row-reverse' }}
          SectionContentBoxProps={SectionContentBoxProps}
          onChangeInViewport={onChangeInViewport}
          SideComponent={SideComponent}
          disableFade={disableFade}
        >
          {children}
        </SideContent>
      )}
    </Box>
  );
}

interface SideContentProps extends ContentProps {
  GridContainerProps?: GridProps;
  GridItemContainerProps?: GridProps;
  SectionContentBoxProps?: BoxProps;
  SideComponent?: React.ReactNode;
}

function SideContent(props: SideContentProps) {
  const {
    children,
    position,
    subtitle,
    contentContainerProps,
    title,
    caption,
    contentBeforeTitle,
    actions,
    captionTypographyProps,
    titleTypographyProps,
    contentStackProps,
    subtitleTypographyProps,
    actionsStackProps,
    GridContainerProps,
    GridItemContainerProps,
    SectionContentBoxProps,
    onChangeInViewport,
    SideComponent,
    disableFade,
  } = props;

  return (
    <Container maxWidth={'xl'}>
      <Box sx={{ py: 10 }} {...SectionContentBoxProps}>
        <Grid
          container
          {...GridContainerProps}
          spacing={4}
          // flexDirection={position === 'right' ? 'row' : 'row-reverse'}
        >
          {SideComponent && (
            <Grid item xs={12} md={12} lg={4}>
              {SideComponent}
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={8} {...GridItemContainerProps}>
            <Content
              position={position}
              title={title}
              subtitle={subtitle}
              contentBeforeTitle={contentBeforeTitle}
              caption={caption}
              contentContainerProps={contentContainerProps}
              actions={actions}
              captionTypographyProps={captionTypographyProps}
              actionsStackProps={actionsStackProps}
              contentStackProps={{
                ...contentStackProps,
              }}
              subtitleTypographyProps={subtitleTypographyProps}
              titleTypographyProps={titleTypographyProps}
              onChangeInViewport={onChangeInViewport}
              disableFade={disableFade}
            >
              {children}
            </Content>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

interface CenterContentProps extends ContentProps {
  ContainerProps?: ContainerProps;
  SectionContentBoxProps?: BoxProps;
}

function CenterContent(props: CenterContentProps) {
  const {
    children,
    position,
    subtitle,
    contentContainerProps,
    title,
    caption,
    actions,
    contentBeforeTitle,
    captionTypographyProps,
    titleTypographyProps,
    contentStackProps,
    subtitleTypographyProps,
    actionsStackProps,
    ContainerProps,
    SectionContentBoxProps,
    onChangeInViewport,
    disableFade,
  } = props;

  return (
    <Container maxWidth="xl" {...ContainerProps}>
      <Box sx={{ px: 1, py: 10 }} {...SectionContentBoxProps}>
        <Content
          position={position}
          title={title}
          subtitle={subtitle}
          caption={caption}
          contentContainerProps={contentContainerProps}
          actions={actions}
          contentBeforeTitle={contentBeforeTitle}
          captionTypographyProps={captionTypographyProps}
          actionsStackProps={actionsStackProps}
          contentStackProps={{
            ...contentStackProps,
          }}
          subtitleTypographyProps={subtitleTypographyProps}
          titleTypographyProps={titleTypographyProps}
          onChangeInViewport={onChangeInViewport}
          disableFade={disableFade}
        >
          {children}
        </Content>
      </Box>
    </Container>
  );
}

interface FadeComponentProps extends FadeProps {
  disableFade?: boolean;
}

const FadeComponent = ({
  children,
  disableFade,
  ...props
}: FadeComponentProps) => {
  return disableFade ? <>{children}</> : <Fade {...props}>{children}</Fade>;
};

interface ContentProps {
  children?: React.ReactNode;
  position: 'right' | 'left' | 'center';
  caption?: string | React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  actions?: React.ReactNode[];
  contentBeforeTitle?: React.ReactNode;
  captionTypographyProps?: TypographyProps;
  contentContainerProps?: ContainerProps;
  titleTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
  contentStackProps?: StackProps;
  actionsStackProps?: StackProps;
  onChangeInViewport?: (inViewPort: boolean) => void;
  disableFade?: boolean;
}

function Content(props: ContentProps) {
  const {
    children,
    position,
    contentBeforeTitle,
    subtitle,
    contentContainerProps,
    title,
    caption,
    actions,
    captionTypographyProps,
    titleTypographyProps,
    contentStackProps,
    subtitleTypographyProps,
    actionsStackProps,
    onChangeInViewport,
    disableFade,
  } = props;

  const ref = useRef();
  const { isMobile } = useWindowDimensions();
  const [shown, setShown] = useState<boolean>(false);
  const inViewport = useIntersection(ref, isMobile ? '200px' : '-300px');
  const textAlign = isMobile
    ? 'center'
    : position === 'right'
    ? 'left'
    : position;

  useEffect(() => {
    if (inViewport) setShown(true);

    if (onChangeInViewport) {
      onChangeInViewport(inViewport);
    }
  }, [inViewport, onChangeInViewport]);

  return (
    // <FadeComponent disableFade={disableFade} in={shown || inViewport}>
    <div ref={ref}>
      {contentBeforeTitle && contentBeforeTitle}
      {title && (
        <Container
          sx={{
            ml: position === 'left' ? 0 : 'auto',
            mr: position === 'right' ? 0 : 'auto',
          }}
          maxWidth={'md'}
          disableGutters
          {...contentContainerProps}
        >
          <Stack
            alignItems={position}
            alignContent={position}
            direction="column"
            textAlign={textAlign}
            spacing={2}
            {...contentStackProps}
          >
            <Typography
              fontWeight={500}
              variant="caption"
              textTransform="uppercase"
              fontSize={17}
              textAlign={textAlign}
              {...captionTypographyProps}
            >
              {caption}
            </Typography>
            <Typography
              variant="h1"
              fontWeight={600}
              textAlign={textAlign}
              lineHeight={1.5}
              {...titleTypographyProps}
            >
              {title}
            </Typography>
            <Typography
              component={'span'}
              variant="body1"
              fontSize={20}
              textAlign={textAlign}
              {...subtitleTypographyProps}
            >
              {subtitle}
            </Typography>
          </Stack>
          <Box sx={{ py: 2 }} />
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            {...actionsStackProps}
          >
            {actions}
          </Stack>
        </Container>
      )}
      {children}
    </div>
    // </FadeComponent>
  );
}

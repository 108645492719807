import React, { useEffect } from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { Box, ThemeProvider } from '@mui/material';
import { DefaultDarkTheme } from '../../themes/default-dark.theme';
import { Cookies, CookiesProps } from '../Misc/Cookies.popup';
import { WhatsappMessenger } from '../Misc/WhatsappMessenger';
import {
  ArticleJsonLd,
  ArticleJsonLdProps,
  FAQPageJsonLd,
  FAQPageJsonLdProps,
  LocalBusinessJsonLd,
  LocalBusinessJsonLdProps,
  NextSeo,
} from 'next-seo';
import { NextSeoProps } from 'next-seo/lib/types';
import { useRouter } from 'next/router';
import DefaultSEO from '../../next-seo.config';
import { MarqtingDarkTheme } from '../../themes/marqting-dark.theme';
import { CommuniqateDarkTheme } from '../../themes/communiqate-dark.theme';
import { PageOpenGraph } from './PageOpenGraph';

const PageWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  minHeight: '80vh',
}));

export interface PageProps {
  children: React.ReactNode;
  title?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  theme?: Theme;
  logo?: string;
  cookiesProps?: CookiesProps;
  seo?: NextSeoProps;
  LocalBusinessJson?: LocalBusinessJsonLdProps;
  ArticleJson?: ArticleJsonLdProps;
  FAQPageJson?: FAQPageJsonLdProps;
}

export function Page(props: PageProps) {
  const {
    children,
    LocalBusinessJson,
    title,
    backgroundColor,
    ArticleJson,
    backgroundImage,
    theme,
    logo,
    cookiesProps,
    seo,
    FAQPageJson,
  } = props;

  const { pathname } = useRouter();

  useEffect(() => {
    if (title) {
      document.title = `${title} | AppCenter Nederland`;
    }
  }, [title]);

  const fullPath = DefaultSEO.url + pathname;
  const currentTheme = theme || DefaultDarkTheme;

  const getAppName = () => {
    switch (currentTheme.palette.primary.main) {
      case CommuniqateDarkTheme.palette.primary.main:
        return 'communiqate';
      case MarqtingDarkTheme.palette.primary.main:
        return 'marqting';
      default:
        return 'acn';
    }
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <PageOpenGraph theme={currentTheme} app={getAppName()} />
      <NextSeo
        canonical={fullPath}
        title={`${title} | AppCenter Nederland`}
        openGraph={{
          url: fullPath,
        }}
        {...seo}
      />
      <LocalBusinessJsonLd
        type={'ProfessionalService'}
        name={'AppCenter Nederland'}
        description={
          'Onze slimme producten op ons krachtige platform zorgen voor persoonlijke benadering en schaalbare groei. Deze unieke combinatie ondersteunt de bedrijfsvoering met slimme oplossingen voor maximaal rendement.'
        }
        telephone={'+31850604011'}
        address={{
          streetAddress: 'Helmerstraat 412',
          addressLocality: 'Enschede',
          addressRegion: 'OV',
          postalCode: '7546PD',
          addressCountry: 'NL',
        }}
        geo={{
          latitude: '52.223942',
          longitude: '6.951590',
        }}
        images={[
          'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/featured_img_home.jpg',
        ]}
        openingHours={[
          {
            opens: '09:00',
            closes: '17:00',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          },
        ]}
        priceRange={'€29 - €649'}
        id={fullPath}
        url={fullPath}
        {...LocalBusinessJson}
      />
      {ArticleJson && <ArticleJsonLd {...ArticleJson} />}
      {FAQPageJson && <FAQPageJsonLd {...FAQPageJson} />}
      <Box
        component="main"
        sx={{
          overflowX: 'hidden',
          bgcolor: backgroundColor ? backgroundColor : '',
          backgroundImage: backgroundImage ? backgroundImage : '',
        }}
      >
        <Header logo={logo} />
        <PageWrapper>{children}</PageWrapper>
        <Footer />
        <WhatsappMessenger />
        <Cookies {...cookiesProps} />
      </Box>
    </ThemeProvider>
  );
}

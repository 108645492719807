import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

interface HighLightedTextProps {
  text: string | React.ReactNode;
  TypographyProps?: TypographyProps;
}

export const HighLightedText = ({
  text,
  TypographyProps,
}: HighLightedTextProps) => (
  <Typography
    variant="inherit"
    component="span"
    fontWeight={600}
    sx={{
      background: 'white',
      px: 1,
      lineHeight: 1.75,
      py: 0.5,
      borderRadius: '4px',
      ...TypographyProps?.sx,
    }}
    {...TypographyProps}
  >
    {text}
  </Typography>
);

import {
  Box,
  Button,
  ButtonProps,
  Chip,
  ClickAwayListener,
  Container,
  Drawer,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import Image from 'next/image';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useRouter } from 'next/router';
import {
  ACN_CLOUD_ROUTE,
  COMMUNIQATE_ROUTE,
  CUSTOMER_SERVICE_ROUTE,
  KENNISBANK_ROUTE,
  KLANTCASES_ROUTE,
  MARQTING_ROUTE,
} from '../../../pages/routes';
import { useWindowDimensions } from '../../../hooks';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

interface ClickAwayProps {
  enabled: boolean;
  onClickAway: CallableFunction;
  children: React.ReactNode;
}

function ClickAway(props: ClickAwayProps) {
  const { enabled, onClickAway, children } = props;

  // if (!enabled) {
  //   return <>{children}</>;
  // }

  const clickAway = () => {
    if (enabled) {
      onClickAway();
    }
  };

  return (
    <ClickAwayListener onClickAway={() => clickAway()}>
      <Box>{children}</Box>
    </ClickAwayListener>
  );
}

interface CloudMegaMenuProps {
  visible: boolean;
  onClose: CallableFunction;
}

export function CloudMegaMenu(props: CloudMegaMenuProps) {
  const { visible, onClose } = props;
  const router = useRouter();

  const { isMobile } = useWindowDimensions();

  const navigate = (path: string) => {
    onClose();
    router.push(path);
  };

  return (
    <Drawer
      anchor={'top'}
      open={visible}
      onClose={() => onClose()}
      variant="temporary"
      sx={{
        width: '100%',
        minHeight: 300,
        position: 'relative',
        zIndex: 3,
      }}
      PaperProps={{
        sx: {
          pt: 12,
          pb: 6,
          backgroundImage: 'unset',
        },
      }}
    >
      <Container maxWidth={'xl'} role="presentation">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Image
              src={
                'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_cloud_logo_white.svg'
              }
              height={38}
              width={200}
            />
            <Box mt={2} />
            <Typography paragraph color={'inherit'}>
              ACN Cloud is ons krachtige schaalbare platform die de unieke
              mogelijkheid biedt de brug te slaan tussen online en offline. kies
              voor rendementsgroei, kies voor een hogere klantretentie, kies
              voor ACN Cloud.
            </Typography>
            <Box mt={3} />
            <Button
              fullWidth
              variant="outlined"
              size={'large'}
              onClick={() => navigate(ACN_CLOUD_ROUTE)}
              color="inherit"
              endIcon={<ArrowForwardIcon />}
            >
              Ontdek ACN Cloud
            </Button>
            <Box mt={2} />
            <Button
              fullWidth
              variant={'text'}
              color={'inherit'}
              size={'large'}
              startIcon={<WhatsAppIcon />}
              href="https://wa.me/31850604011"
              target="_blank"
            >
              Vragen? Stel je vraag via WhatsApp
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Typography sx={{ ml: 1, mt: 1 }} variant={'h6'} fontWeight={600}>
              Oplossingen
            </Typography>
            <Box mt={2} />
            <AppListItem
              onClick={() => navigate(COMMUNIQATE_ROUTE)}
              image={
                'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/communiqate_beeldmerk.svg'
              }
              title={'CommuniQate'}
              description={
                'TIM transformeert klantinteractie op een persoonlijke manier. Dat zorgt voor exponentiële omzetgroei'
              }
            />
            <Box mt={2} />
            <AppListItem
              onClick={() => navigate(MARQTING_ROUTE)}
              image={
                'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/marqting_beeldmerk.svg'
              }
              title={'MarQting'}
              description={
                'Maakt de offline wereld meetbaar. Dat zorgt voor betere beslissingen en meer rendement.'
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={4} xl={4}>
            <Typography sx={{ ml: 1, mt: 1 }} variant={'h6'} fontWeight={600}>
              Informatie
            </Typography>
            <Box mt={2} />
            <AppListItem
              onClick={() => navigate(KENNISBANK_ROUTE)}
              icon={<LibraryBooksIcon sx={{ fontSize: '4.2rem' }} />}
              title={'Kennisbank'}
              // comingSoon
              description={
                'Doe inspiratie op, lees handige tips en ontdek nieuwe functionaliteiten van ons platform. '
              }
            />
            <Box mt={2} />
            <AppListItem
              onClick={() => navigate(KLANTCASES_ROUTE)}
              icon={<SwitchAccountIcon sx={{ fontSize: '4.2rem' }} />}
              title={'Klantcases'}
              description={
                'Lees de ervaringen van onze klanten die reeds gebruik maken van ons platform.'
              }
            />
            {isMobile && (
              <>
                {' '}
                <Box mt={2} />
                <AppListItem
                  onClick={() => navigate(CUSTOMER_SERVICE_ROUTE)}
                  icon={<SupportAgentIcon sx={{ fontSize: '4.2rem' }} />}
                  title={'Klantenservice'}
                  description={
                    'Stel je vraag aan onze deskundige klantenservice.'
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
}

interface AppListItemProps extends ButtonProps {
  image?: string;
  icon?: React.ReactNode;
  title: string;
  comingSoon?: boolean;
  description: string;
}

function AppListItem(props: AppListItemProps) {
  const { image, title, description, icon, comingSoon, ...ButtonProps } = props;
  return (
    <Button
      sx={{
        p: 1,
        borderRadius: '4px',
        minHeight: 100,
        width: '100%',
        justifyContent: 'left',
      }}
      color={'inherit'}
      {...ButtonProps}
    >
      {image && (
        <Box sx={{ width: 60, mt: 1 }}>
          <Image src={image} width={60} height={60} layout={'fixed'} />
        </Box>
      )}

      {icon && <Box sx={{ width: 60, mt: 1 }}>{icon}</Box>}

      <Stack sx={{ ml: 3 }} direction={'column'}>
        <Typography
          sx={{ textTransform: 'none' }}
          align={'left'}
          color={'inherit'}
          variant={'h6'}
        >
          {title}
          {comingSoon && (
            <Chip
              sx={{ ml: 1, borderRadius: '4px', pt: 0.25 }}
              label="Binnenkort"
              variant="filled"
              color={'primary'}
              size={'small'}
            />
          )}
        </Typography>
        <Typography
          sx={{ textTransform: 'none' }}
          align={'left'}
          color={'inherit'}
          variant={'body2'}
        >
          {description}
        </Typography>
      </Stack>
    </Button>
  );
}

import {
  Box,
  Container,
  Paper,
  PaperProps,
  Stack,
  Typography,
} from '@mui/material';
import { Navigation } from './Navigation';
import { CopyRight } from './CopyRight';
import { styled } from '@mui/material/styles';
import React from 'react';
import Image from 'next/image';

const FooterWrapper = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: theme.spacing(4),
  zIndex: 2,
}));

function Footer() {
  return (
    <FooterWrapper>
      <Container maxWidth="xl">
        <Box py={2} />
        <Navigation />
        <Box mt={8} />
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
          spacing={4}
          sx={{
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            height={45}
            width={215}
            alt={'WhatsApp Business'}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/wa_business.png'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            alt={'Facebook Certified Marketing Developer'}
            height={85}
            width={85}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/facebook_marketing_developer.png'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://play.google.com/store/apps/dev?id=7048622903548929218'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            height={40}
            width={231}
            alt={'Google Developers'}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/google_developers.svg'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            height={75}
            width={91}
            alt={'DUNS Registered'}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/duns_registered.png'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            alt={'Apple Developer'}
            height={35}
            width={167}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/apple_developer.svg'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            alt={'S-BB Keurmerk'}
            height={85}
            width={85}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/s-bb_keurmerk.png'
            }
          />
          {/*</AppLink>*/}
          {/*<AppLink*/}
          {/*  target={'_blank'}*/}
          {/*  href={*/}
          {/*    'https://order.altares.nl/checkout/?company_name=AppCenter%20Nederland'*/}
          {/*  }*/}
          {/*>*/}
          <Image
            height={35}
            width={98}
            alt={'Powered by AWS'}
            src={
              'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/powered_by_aws.png'
            }
          />
          {/*</AppLink>*/}
        </Stack>
        <Box py={2} />
        <CopyRight />
        <Box py={2} />
        <Typography paragraph align={'center'} variant={'caption'}>
          AppCenter Nederland maakt geen onderdeel uit van Facebook, Google,
          WhatsApp, Apple of Amazon AWS. Wij zijn op geen enkele manier gelinkt
          aan bovenstaande bedrijven. Facebook en WhatsApp zijn handelsnamen van
          Meta, Inc. Google is een handelsnaam van Google LLC. Amazon AWS is een
          handelsnaam van Amazon Web Services, Inc. Apple is een handelsnaam van
          Apple, Inc.
        </Typography>
      </Container>
    </FooterWrapper>
  );
}

export { Footer };

import { SvgIcon, SvgIconProps } from '@mui/material';

export function AcnCloudIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 384.8933 238.0437"
      preserveAspectRatio={'xMidYMid meet'}
      {...props}
    >
      <path
        d="M384.8933,0c-28.8951,3.105-50.1772,16.0418-63.154,31.3939l-12.804-1.2072L284.5385,54.5085l23.5316,2.07.1732-.345a55.3145,55.3145,0,0,0-1.3845,11.5572c-32.7015,33.1192-89.454,89.525-137.209,102.462-4.4989-6.3822-13.6691-10.6944-20.2442-10.6944a22.38,22.38,0,0,0-13.8419,4.14,17.0483,17.0483,0,0,1-1.2113,10.1771c4.1526-22.08-28.203-33.9818-41.353-15.18a22.67,22.67,0,0,0-12.1119-5.1751A52.1049,52.1049,0,0,1,92.48,132.6488c19.5518-21.5618,52.9459-22.4241,76.4772-4.83-10.3815-17.2493-23.1852-24.4941-32.8748-27.4268a60.3939,60.3939,0,0,1,57.4445-41.5715c29.0683,0,46.3709,16.3871,57.6176,41.7442l22.1474-18.8024h-2.25c-2.4225,0-5.0177.1726-7.7859.3449l-.6924.1727A78.1392,78.1392,0,0,0,163.0748,47.091c-.1635-.0711-.3322-.13-.4961-.2a80.6527,80.6527,0,0,0-45.7438,52.91,72.2514,72.2514,0,0,0-39.79,22.591,70.8218,70.8218,0,0,0-16.9508,32.5528s-5.8883-38.6009,47.8194-64.5715A91.8269,91.8269,0,0,1,146.949,42.2115a79.87,79.87,0,0,0-14.5-1.33A78.1168,78.1168,0,0,0,56.8368,98.84a70.5327,70.5327,0,0,0-38.7575,21.9068c-24.9159,27.6-24.05,70.3782,2.4224,96.5975a70.4878,70.4878,0,0,0,49.8315,20.7H271.0425a78.6007,78.6007,0,0,0,55.5413-22.942c37.2-37.0866,25.954-94.01-11.2464-119.7118l-12.804,13.8c32.3558,18.63,40.8338,63.8233,11.2467,93.1477a60.2219,60.2219,0,0,1-42.7376,17.5943H215.1552a27.5116,27.5116,0,0,0,.8652-6.727c0-11.3848-7.2669-21.9069-19.0326-21.5619a16.2433,16.2433,0,0,1,8.9971.5173c18.1678-35.3615,85.9938-97.46,110.7366-114.3646a49.0921,49.0921,0,0,0,11.7656-1.5523l-.3461.3447,2.0765,23.46,24.3966-24.322-1.2112-12.7646C368.8022,50.0235,381.779,28.8067,384.8933,0ZM347.0008,37.7766a9.04,9.04,0,0,1,0-12.9373,9.2628,9.2628,0,0,1,12.9769,0,9.359,9.359,0,0,1,0,12.9373A9.1061,9.1061,0,0,1,347.0008,37.7766Z"
        style={{ fill: 'currentcolor' }}
      />
    </SvgIcon>
  );
}

import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Fade,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { PRIVACY_POLICY_ROUTE } from '../../pages/routes';
import AppLink from '../Link/AppLink';
import { HighLightedText } from '../HighLightedText/HighLightedText';

export interface CookiesProps {
  isAppPage?: boolean;
}

function Cookies(props: CookiesProps) {
  const { isAppPage } = props;
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [cookiesAccepted, setCookiesAccepted] = React.useState(false);

  const acceptCookies = async () => {
    setCookiesAccepted(true);
    localStorage.setItem('cookies-accepted', 'true');
  };

  useEffect(() => {
    (async () => {
      const cookieItem = localStorage.getItem('cookies-accepted');
      const accepted = cookieItem === 'true';
      setCookiesAccepted(accepted as boolean);
    })();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {!loading && !cookiesAccepted && (
        <Fade in>
          <Card
            elevation={1}
            sx={{
              position: 'fixed',
              width: '100%',
              bottom: 0,
              padding: 4,
              zIndex: theme.zIndex.modal,
              bgcolor: 'secondary.main',
            }}
          >
            <Box
              sx={{
                ml: 0,
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Container maxWidth={'md'}>
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight={600}
                  sx={{ color: 'white' }}
                >
                  Wij gebruiken
                </Typography>{' '}
                <HighLightedText
                  TypographyProps={{
                    color: isAppPage ? 'primary' : 'secondary',
                    variant: 'h5',
                  }}
                  text={'cookies.'}
                />
                <Box mt={2.5} />
                <Typography sx={{ color: 'white' }} paragraph={true}>
                  Wij plaatsen Cookies om jou de best mogelijke ervaring te
                  geven. Ook gebruiken we deze voor advertentiedoeleinden. Welke
                  we plaatsen kun je lezen in onze{' '}
                  <AppLink href={PRIVACY_POLICY_ROUTE} underline={'always'}>
                    Privacy voorwaarden
                  </AppLink>
                  .
                </Typography>
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  sx={{ width: '100%', justifyContent: 'center' }}
                  spacing={2}
                >
                  <Button
                    component={AppLink}
                    variant="outlined"
                    color="primary"
                    href={PRIVACY_POLICY_ROUTE}
                  >
                    Meer informatie
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={acceptCookies}
                  >
                    Geen probleem
                  </Button>
                </Stack>
              </Container>
            </Box>
          </Card>
        </Fade>
      )}
    </>
  );
}

export { Cookies };

import { SvgIcon, SvgIconProps } from '@mui/material';

export function MarqtingIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 300 300"
      preserveAspectRatio={'xMidYMid meet'}
      {...props}
    >
      <g
        id="Layer_2_00000036935961536864773500000016739332626019981967_"
        style={{ fill: 'currentcolor' }}
      >
        <g id="Layer_1-2">
          <path
            className="st0"
            d="M113.7,69.7v43.8H69.9V69.7H113.7 M135.6,47.8H48v87.6h87.6V47.8z M113.7,186.5v43.8H69.9v-43.8H113.7
			 M135.6,164.6H48v87.6h87.6V164.6z M230.5,69.7v43.8h-43.8V69.7L230.5,69.7 M252.4,47.8h-87.6v87.6h87.6V47.8z"
          />
          <path
            className="st0"
            d="M189.2,252.4h-24.1v-58.8h24.1L189.2,252.4z M221,164.2h-24.1v88.2H221V164.2z M252.7,203.4h-24.1v49h24.1
			L252.7,203.4z"
          />
          <polygon
            className="st0"
            points="300,75 270,75 270,30 225,30 225,0 300,0 		"
          />
          <polygon
            className="st0"
            points="300,300 300,225 270,225 270,270 225,270 225,300 		"
          />
          <polygon
            className="st0"
            points="0,300 75,300 75,270 30,270 30,225 0,225 		"
          />
          <polygon className="st0" points="0,0 0,75 30,75 30,30 75,30 75,0 		" />
        </g>
      </g>
    </SvgIcon>
  );
}
